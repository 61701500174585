import styled from "styled-components";
import { SVGLinkedin, SVGYoutube } from "@/lib/svg";

export const Container = styled.div`
  display: flex;
  gap: 1.625rem;
  align-items: baseline;
`;

export const YoutubeLogo = styled(SVGYoutube)`
  width: 1.25rem;
  height: auto;
`;

export const LinkedInLogo = styled(SVGLinkedin)`
  width: 1.25rem;
  height: auto;
`;

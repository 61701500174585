import { Container, LinkedInLogo, YoutubeLogo } from "./styles";

type Props = {
  linkedin?: string;
  youtube?: string;
};

const SocialLinks: React.FC<Props> = (props) => {
  const { linkedin, youtube } = props;
  return (
    <Container>
      {linkedin && (
        <a href={linkedin} target="_blank" rel="noreferrer">
          <LinkedInLogo />
        </a>
      )}
      {youtube && (
        <a href={youtube} target="_blank" rel="noreferrer">
          <YoutubeLogo />
        </a>
      )}
    </Container>
  );
};

export default SocialLinks;
